import b3 from '../../assests/A2_Cricket_-_Cricket_Bat_Maker_in_India_300x.png'
import b4 from '../../assests/A2_Cricket_-_Cricket_Bat_Manufacturers_in_India_300x.png'
import b2 from '../../assests/Kashmir_Willow_Cricket_Bats_-_1080_x_1080_banner_500241a7-114c-4a0f-9541-3764cddebaa1_300x.jpg'
import b1 from '../../assests/english_willow_banner_homepage_e22c5aa9-059c-4896-ad86-65853ee06b90_300x.jpg'


 export const bannerData = [

    {
        id:1,
        desc:"ENGLISH WILLOW BATS",
        image:b1,
    },
    {
        id:2,
        desc:"KASHMIRI WILLOW BATS",
        image:b2,
    },
   
    {
        id:3,
        desc:"BATS THAT PERFORM BETTER",
        image:b3,
    },
    {
        id:4,
        desc:"ELLIE CRICKET KIT FOR PROFESSIONAL",
        image:b4,
    },
]