import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { bannerData } from './data.js'
import './index.css'
const Banner = () => {
  return (
    <div className='banners mx-5'>
        <Row className="">
            {
                bannerData?.map((data)=>(
            <Col lg={6} md={6} sm={12} key={data.id} className="my-2">
             <div className='banner'>
                <img src={data.image} alt='' style={{width:"100%"}}/>
                <div className='content'>
                 <p>{data.desc}</p>
                 <Link className='btn'>SHOP NOW</Link>
                </div>
             </div>
            </Col>
                ))
            }
        </Row>
    </div>
  )
}

export default Banner