import React from 'react'
import Navvbar from './Navvbar'
import Promotion from './Promotion'
import './index.css'
const Header = () => {
  return (
    <div style={{position:'fixed',top:0,left:0,right:0,zIndex:9999}}>
      <Promotion/>
      <Navvbar/>
    </div>
  )
}

export default Header
