import React from 'react';
import HomeCards from '../cards/HomeCards.jsx';
import { hotSeller } from './data.js';
import './index.css';
const HotSeller = () => {
  return (
    <section className='hotSeller'>
        <h2>HOTSELLERS</h2>
        <div className='cards d-flex justify-content-center align-items-center gap-3 flex-wrap'>
            {
                hotSeller?.map((data)=>(
                    <HomeCards data={data} key={data.id}/>
                ))
            }
        </div>
    </section>
  )
}

export default HotSeller