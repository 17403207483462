import React from 'react'
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import './index.css'
const Footer = () => {
  return (
    <div style={{width:"100%",height:"auto",padding:"20px 0",backgroundColor:"#081138"}}>
   <section className="footer">
   
    <div className='navLinks'>
    <div className='logo'>
        <h2>DVICTA</h2>
        <p>
        We’re believers. We’re revolutionaries. We <br></br>work 
        with the highest ethics of craftsmanship,<br></br>
        innovation, and transparency to achieve<br></br> your dream.
        </p>
    </div>
    <div className='links'>
        <Link>HOME</Link>
        <Link>BATS</Link>
        <Link>PREMADE BATS</Link>
        <Link>KIT</Link>
        <Link>TEAMWEAR</Link>
        <Link>CUSTOM BAT</Link>
        <Link>ACCESSORIES</Link>
        <Link>BRAND</Link>
    </div>
    <div className='links'>
        <Link>ABOUT US</Link>
        <Link>CONTACT US</Link>
        <Link>LEGAL</Link>
        <Link>PRIVACY POLICY</Link>
        <Link>REFUND POLICY</Link>
        <Link>TERMS OF SERVICES</Link>
        <Link>WARRANTY</Link>
        
    </div>
    <div className='socialMedia'>
        <a href=''><FaInstagram/></a>
        <a href=''><FaFacebook/></a>
        <a href=''><FaTwitter/></a>
        <a href=''><FaYoutube/></a>
    </div>
    </div>
    <div>
        <p style={{color:"#fff"}}>@2024 CODERBABAS - ALL RIGHTS RESERVERD</p>
    </div>
   </section>
   </div>
  )
}

export default Footer