import React from "react";
import Carousel from "react-bootstrap/Carousel";
const Promotion = () => {
  return (
    <Carousel className="promotion">
      <Carousel.Item >
        <Carousel.Caption>
          <p>Limited Time Offer: 20% off on kit bags</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item >
        <Carousel.Caption>
          <p>Limited Time Offer : 10% off on Kashmiri willow bats</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Promotion;
