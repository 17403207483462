import React from 'react'
import Banner from '../../components/banner/Banner'
import SubBanner from '../../components/banner2/SubBanner'
import BatCards from '../../components/batcards/BatCards'
import Review from '../../components/carouselReview/Review'
import Hero from '../../components/hero/Hero'
import HotSeller from '../../components/hotSeller/HotSeller'
import './index.css'
const Home = () => {
  return (
    <div className='home'>
        <Hero/>
        <HotSeller/>
        <Banner/>
        <BatCards/>
        <SubBanner/>
        <Review/>
    </div>
  )
}

export default Home