import React from 'react'
import banner from '../../assests/A2_website_banner_main-5_Large_e5053da5-961a-442e-a12e-edfe271c3c03_1800x.jpg'
const SubBanner = () => {
  return (
    <div>
        <img src={banner} alt='' style={{width:"100%"}}/>
    </div>
  )
}

export default SubBanner