import React from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import './index.css';
const HomeCards = ({data}) => {
  return (
    <Card className="c" style={{ width: '25rem' , height:"auto",cursor:"pointer",position:"relative",border:"none"}}>
    <Card.Img variant="top" src={data.image} style={{width:"100%",height:"370px"}}/>
    <Card.Body>
    <Card.Title style={{fontSize:"1.8rem",textAlign:"center"}}>{data.name}</Card.Title>
    <Card.Text style={{fontSize:"1.6rem",textAlign:"center"}}>&#8377; {data.price}</Card.Text>
    </Card.Body>
    <div className='overlay'>
        <Link className="btn">Quick View</Link>
    </div>
   </Card>
  )
}

export default HomeCards
