import React from "react";

import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./pages/home/Home";

const App = () => {
  return (
    <div className="app">
      <Header />
      <Home/>
      <Footer/>
    </div>
  );
};

export default App;
