import h2 from '../../assests/gloves.JPG'
import h1 from '../../assests/h1.jpg'
import h3 from '../../assests/h3.jpg'
import h4 from '../../assests/h4.jpg'


export const hotSeller = [
    {
        id:1,
        name:"Apex Kashmir Willow Cricket Bat - CBP Lite",
        price:"6000.00",
        image:h1
    
    },
    {
        id:2,
        name:"Cricket Batting Gloves - Splitx - 2024",
        price:"3,200.00",
        image:h2
    },
    {
        id:3,
        name:"Grade 3 English Willow Cricket Bat - Omega",
        price:"16,000.00",
        image:h4
    },
    {
        id:4,
        name:"4 Piece Leather Cricket Ball - T20 Red (Box of 3 balls)",
        price:"1.830.00",
        image:h3
    },
]