import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
const Navvbar = () => {
  
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home" className="logo">DVICTA</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto nav" >
            <Nav.Link href="#home" className="links">HOME</Nav.Link>
           
            <NavDropdown title="BATS" id="basic-nav-dropdown" className="links">
              <NavDropdown.Item href="#action/3.1">ENGLISH WILLOW BATS</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
               KASHMIRI WILLOW BATS
              </NavDropdown.Item>
              
            </NavDropdown>
           
            <Nav.Link href="#link" className="links">PREMADE BATS</Nav.Link>
            <NavDropdown title="KIT" id="basic-nav-dropdown" className="links">
              <NavDropdown.Item href="#action/3.1">BATTING GLOVES</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                BATTING PADS
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">THIGH PADS</NavDropdown.Item>
              
              <NavDropdown.Item href="#action/3.4">
                
                KIPPING GLOVES
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#link" className="links">TEAMWEAR</Nav.Link>
            <Nav.Link href="#link" className="links">CUSTOM BATS</Nav.Link>
            <Nav.Link href="#link" className="links">ACCESSORIES</Nav.Link>
            <Nav.Link href="#link" className="links">BRANDS</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navvbar