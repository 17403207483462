
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { data } from './data.js';
import './index.css';
const Review = () => {
    const [scrollX, setScrollX] = useState(0);
    const [innerWidth, setInnerWidth] = useState(0);
  
    useEffect(() => {
      const handleResize = () => {
        setInnerWidth(document.querySelector(".inner").clientWidth);
        
      };
  
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    const handlePrev = () => {
        const step =400; 
        // Adjust the step size as per your requirement
       
        setScrollX((prev) => Math.max(prev + step));
        
      }
    
      const handleNext = () => {
        const step = 400; // Adjust the step size as per your requirement
        const containerWidth = document.querySelector(".inner").scrollWidth;
        const visibleWidth = document.querySelector(".inner").clientWidth ;
        setScrollX((prev) => Math.min(prev - step, containerWidth - visibleWidth));
      };
    
  return (
    <section className="newArrived">
      <div className="bottom">
        <div className="inner" style={{ transform: `translateX(${scrollX}px)` }}>
          {data.map((item) => (
            <div className="newCard" key={item.id}>
    <div className="image">
      <img src={item.image} alt="" />
    </div>
    <div className="content">
      <h5 className="brandName">{item.name}</h5>
      <p>{item.desc}</p>
   
    </div>
  </div>
           
          ))}
        </div>
        <div className="arrowBtn">
          <div className="prev">
            <button onClick={handlePrev}  >
              <FaArrowLeft />
            </button>
          </div>
          <div className="next">
            <button onClick={handleNext} disabled={scrollX >= (document.querySelector(".inner")?.scrollWidth || 0 ) + innerWidth}>
              <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Review
