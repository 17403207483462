import React from 'react';
import Carousel from "react-bootstrap/Carousel";
import { Link } from 'react-router-dom';
import img1 from '../../assests/English_Willow_Cricket_Bats_-_Banner_-_Desktop.png';
import img2 from '../../assests/Kashmir_Willow_Cricket_Bat_-_Desktop_Banner.png';
import img3 from '../../assests/softs_with_grain_1800x.jpg';
import './index.css';
const Hero = () => {
  return (
    <Carousel className="hero">
    <Carousel.Item className='item'>
     <img src={img1} alt='dvicta'/>
     <Link className='btn'>Shop Now</Link>
    </Carousel.Item>
    <Carousel.Item  className='item'>
    <img src={img2} alt='dvicta'/>
    <Link className='btn'>Shop Now</Link>
    </Carousel.Item>
    <Carousel.Item  className='item'>
    <img src={img3} alt='dvicta'/>
    <Link className='btn'>Shop Now</Link>
    </Carousel.Item>
  </Carousel>
  )
}

export default Hero
