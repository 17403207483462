import React from 'react'
import HomeCards from '../cards/HomeCards.jsx'
import { data } from './data.js'
const BatCards = () => {
  return (
    <section className='my-5'>
         <div className='cards d-flex justify-content-center align-items-center gap-3 flex-wrap'>
            {
                data?.map((data)=>(
                    <HomeCards  data={data} key={data.id}/>
                ))
            }
        </div>
    </section>
  )
}

export default BatCards
