import b1 from '../../assests/13.jpg'
import b2 from '../../assests/A2Cricket80.jpg'
import b3 from '../../assests/h1.jpg'
import b4 from '../../assests/h4.jpg'
export const data = [
    {
        id:1,
        name:"Grade 1+ English Willow Cricket Bat - Wraith",
        price:"46,000.00",
        image:b1
    },
    {
        id:2,
        name:"Grade 2+ English Willow Cricket Bat - Vertex",
        price:"23,000.00",
        image:b2
    },
    {
        id:3,
        name:"Grade 1+ English Willow Cricket Bat - Cornet",
        price:"30,000.00",
        image:b3
    },
    {
        id:4,
        name:"Grade 1+ Kashmir Willow Cricket Bat - Crest",
        price:"3,500.00",
        image:b4
    },
]