import b1 from '../../assests/UkvDU8SmGi.jpg'
import b2 from '../../assests/gloves.JPG'

export const data = [
    {
        id:1,
        name:"Rohit p",
        desc:"good bat",
        image:b1
    },
    {
        id:2,
        name:"Akshay K",
        desc:"good bat",
        image:b2
    },
    {
        id:3,
        name:"Akshay K",
        desc:"good bat",
        image:b1
    },
    {
        id:4,
        name:"Akshay K",
        desc:"good bat",
        image:b2
    },
    {
        id:5,
        name:"Akshay K",
        desc:"good bat",
        image:b1
    },
    {
        id:6,
        name:"Akshay K",
        desc:"good bat",
        image:b2
    },
]